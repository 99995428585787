/* Navigation Bar Design */
header {
	background: var(--dark-navy);
	top: 0;
	text-align: center;
	width: 100%;
	min-height: 20px;
	position: fixed;
	z-index: 10;
}

/* Optimized Navigation Bar Design for Desktop */
.mobileNavbar { display: none; }

.desktopNavbar ul {
	float: left;
	display: flex;
}

.desktopNavbar ul li { 
	margin: 0px 20px 0px; 
}

.desktopNavbar ul li a {
	position: relative;
	display: block;
	padding: 15px 5px 15px;
	margin: 15px 0;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	font-weight: bold;
	transition: .5s;
	z-index: 2;
}

.desktopNavbar ul li:hover a {
	color: #000;
}

.desktopNavbar ul li a:hover { 
	color: #000; 
}

.desktopNavbar ul li a:hover:before {
	transform: scaleY(0.95);
	opacity: 1;
}

.desktopNavbar ul li a:hover:after { 
	transform: scaleY(0.7); 
}

.desktopNavbar ul li a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	transform: scaleY(2);
	opacity: 0;
	transition: .5s;
}

.desktopNavbar ul li a:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	transform: scale(0);
	transition: .5s;
	z-index: -1;
}

/* Optimized Navigation Bar Design for Tablets and Phones */
@media(max-width: 780px) {
	.desktopNavbar { display: none; }
	.mobileNavbar { display: block; }

	header { 
		position: absolute; 
	}

	.mobileMenuContainer {
		position: fixed;
		top: 12px;
		right: 80px;
		z-index: 1;
	}

	.mobileMenuToggler {
		position: absolute;
		top: 0;
		z-index: 2;
		cursor: pointer;
		width: 50px;
		height: 50px;
		opacity: 0;
	}

	.hamburger {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 60px;
		height: 50px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hamburger > div {
		position: relative;
		width: 50%;
		height: 2px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.4s ease;
	}

	.hamburger > div:before,
	.hamburger > div:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: -10px;
		width: 100%;
		height: 2px;
		background: inherit;
	}

	.hamburger > div:after {
		top: 10px;
	}

	.mobileMenuToggler:checked + .hamburger > div {
		transform: rotate(135deg);
	}

	.mobileMenuToggler:checked + .hamburger > div:before,
	.mobileMenuToggler:checked + .hamburger > div:after {
		top: 0;
		transform: rotate(90deg);
	}

	.mobileMenuToggler:checked:hover + .hamburger > div {
		transform: rotate(225deg);
	}

	.mobileMenuToggler:checked ~ .mobileMenu {
		visibility: visible;
	}

	.mobileMenuToggler:checked ~ .mobileMenu > div {
		transform: scale(1);
		transition-duration: 0.75s;
	}

	.mobileMenuToggler:checked ~ .mobileMenu > div > div {
		opacity: 1;
		transition: opacity 0.4s ease;
	}

	.mobileMenu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mobileMenu > div {
		background: rgba(0, 0, 0, 0.93);
		width: 100%;
		height: 100%;
		display: flex;
		flex: none;
		align-items: center;
		justify-content: center;
		transform: scale(0);
		transition: all 0.4s ease;
	}

	.mobileMenu > div > div {
		text-align: center;
		max-height: 100vh;
		transition: opacity 0.4s ease;
	}

	.mobileNavbar ul {
		margin: 0;
		padding: 0;
	}

	.mobileNavbar ul li {
		list-style: none;
		margin: 50px 20px 50px;
	}

	.mobileNavbar ul li a {
		position: relative;
		display: block;
		padding: 15px 4px 15px;
		margin: 15px 0;
		text-decoration: none;
		text-transform: uppercase;
		color: #fff;
		font-weight: bold;
		transition: .5s;
		z-index: 2;
		font-size: 25px;
	}

	.mobileNavbar ul li a:hover {
		color: #000;
	}

	.mobileNavbar ul li a:hover:before {
		transform: scaleY(0.95);
		opacity: 1;
	}

	.mobileNavbar ul li a:hover:after {
		transform: scaleY(0.7);
	}

	.mobileNavbar ul li a:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;
		transform: scaleY(2);
		opacity: 0;
		transition: .5s;
	}

	.mobileNavbar ul li a:after {
		content: '';
		position: absolute;
		top: 1px;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		transform: scale(0);
		transition: .5s;
		z-index: -1;
	}
}

.mobileMenu > div > div > div > p {
	color: #fff;
	text-decoration: none;
	font-weight: bold;
	font-family: consolas;
	margin: 0px 20px 20px;
	border-radius: 10px;
}

.mobileMenu > div > div > div > p { 
	font-size: 27px; 
	padding-top: 50px; 
}

@media(max-width: 450px) {
	.mobileMenu > div > div > div > p {
		font-size: 21px;
	}
}

.mobileMenu > div > div > div > div a {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	background: #fff;
	margin: 30px 10px;
	text-decoration: none;
	font-size: 28px;
	border-radius: 50%;
	color: #969696;
	box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2),
					   2px 2px 5px rgba(0, 0, 0, 0.2);
	transition: 0.5s;
	display: inline-block;
}

.mobileMenu > div > div > div > div a:hover {
	color: #000;
	border-color: #1d1d1d;
	transform: translateY(-15px);
	box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.7),
					   2px 24px 10px rgba(0, 0, 0, 0.7);
}

.mobileMenu > div > div > div > div a:before {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 2px solid rgba(0, 0, 0, 0.5);
}

.mobileMenu > div > div > div > div a .fa {
	position: relative;
	margin-top: 15px;
}
