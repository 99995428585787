:root {
  --dark-navy: #030b1b;
  --navy: #091424;
  --lightest-navy: #54f4df;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #CFE9FF;
  --light-slate: #a8b2d1;
  --white: #e6f1ff;
  --purple-bright: #c78bff;
  --green-tint: rgba(100, 255, 218, 0.1);
}
