#intro {
  text-align: left;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 5%;
  min-height: 40vh;
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
}

.curved-image {
  border-radius: 10%;
  height: 350px;
  position: relative;
}

.intro-left {
  padding-top: 20%;
}

.intro-name {
  font-size: 55px;
  color: #fff;
}

.intro-emphasize {
  color: var(--purple-bright);
  margin-bottom: -70px;
}

.intro-caption {
  color: var(--slate);
  font-size: 18px;
  margin-top: -30px;
  margin-bottom: 15px;
  width: 90%;
  line-height: 35px;
}

.emphasize-description {
  color: var(--lightest-navy);
  text-decoration: underline;
}

.intro-contact {
  font-size: 20px;
  padding: 10px 30px;
  margin-top: 20px;
  font-family: "Nunito", sans-serif;
  border-radius: 4px;
  margin-right: 50px;
}

.intro-contact-resume {
  background-color: var(--slate);
  color: black;
  border: 1px solid white;
}

.intro-contact-resume > a {
  color: black;
}

.intro-contact-resume:hover {
  background-color: var(--dark-navy);
  border: 1px solid white;
  color: white;
}

.intro-contact-resume:hover > a {
  color: white;
}

.intro-contact-github {
  background-color: var(--dark-navy);
  border: 1px solid white;
}

.intro-contact-github:hover {
  background-color: var(--slate);
  color: black;
}

.intro-contact-github:hover > a {
  color: black;
}

a {
  text-decoration: none;
  color: white;
}

b {
  font-size: 17px;
}

@media only screen and (max-width: 780px) {
  #intro {
    padding: 0;
    display: block;
  }

  .curved-image {
    margin-top: -60px;
  }

  .intro-name {
    font-size: 35px;
  }

  .intro-emphasize {
    color: var(--purple-bright);
    margin-bottom: -40px;
  }

  .intro-caption {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
  }

  .intro-contact {
    width: 100%;
  }
}