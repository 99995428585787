#experiences {
  text-align: left;
  padding-top: 6%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50vh;
  font-family: "Nunito", sans-serif;
  padding-bottom: 10%;
}

.experiences-intro {
  color: #fff;
  font-size: 22px;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
}

.experiences-word {
  font-size: 45px;
  color: var(--lightest-navy);
}

.experiences-description {
  line-height: 35px;
  margin-top: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: var(--light-slate);
}

.tabs {
  overflow: hidden;
  background: transparent;
}

.tabs button {
  color: var(--lightest-navy);
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 16px;
  transition: 0.3s;
  font-size: 22px;
  font-family: "Nunito", sans-serif;
}

.tabs button:hover {
  border-bottom: 2px solid #ddd;
  color: #fff;
}

.tabs button.active {
  border-bottom: 2px solid;
}

.tab-content {
  display: none;
  color: #fff;
  position: relative;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--light-slate);
  padding-left: 18px;
}

.tab-company {
  font-size: 21px;
  color: white;
  margin-top: 30px;
}

.tab-details {
  color: var(--light-slate);
  margin-top: -15px;
  font-size: 18px;
}

.timeline {
  color: var(--slate);
  margin-top: 30px;
  margin-bottom: 30px;
  text-decoration: underline;
}

.tab-content div {
  font-size: 18px;
  color: white;
}

.tab-content div > p {
  line-height: 30px;
}

.highlight {
  color: var(--purple-bright);
}

@media only screen and (max-width: 780px) {
  .experiences-intro {
    display: block;
    font-size: 18px;
    padding: 0;
  }

  .experiences-word {
    text-align: center;
    font-size: 40px;
  }

  .tabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    justify-content: center;
  }

  .tabs button {
    font-size: 20px;
    font-family: "Nunito", sans-serif;
    text-align: center;
  }
  
  .tab-company {
    font-size: 20px;
  }

  .tab-content {
    font-size: 17px;
  }

  .tab-details {
    font-size: 17px;
  }
}
