#skills {
  text-align: left;
  padding-top: 6%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 70vh;
  font-family: "Nunito", sans-serif;
}

.skills-intro {
  color: #fff;
  font-size: 22px;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 0;
}

.tech-stack {
  font-size: 45px;
  color: var(--lightest-navy);
}

.skills-description {
  line-height: 35px;
  margin-top: 10px;
}

.skills-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding: 10px;
  gap: 20px;
  color: var(--purple-bright);
}

.skills-grid-item {
  border-bottom: 3px solid var(--purple-bright);
  background-color: var(--navy);
  padding: 30px;
  font-size: 30px;
  text-align: center;
}

.skills-grid-item p {
  text-align: left;
  margin-top: -10px;
}

.skill-items {
  color: white;
  font-size: 18px;
  margin-top: -5px;
}

@media only screen and (max-width: 780px) {
  .skills-intro {
    display: block;
    font-size: 18px;
    padding: 0;
  }

  .tech-stack {
    text-align: center;
    font-size: 40px;
  }

  .skills-grid-item {
    font-size: 20px;
  }

  .skill-items {
    font-size: 17px;
  }
}